<template>
  <div>
    <v-container fluid>
      <!-- <textarea class="md-text" rows="10" v-model="content" /> -->
      <markdown-it-vue
        ref="myMarkdownItVue"
        :content="content"
        :options="options"
      />
    </v-container>
  </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue';
// import 'markdown-it-vue/dist/markdown-it-vue.css';
import mathjax3 from 'markdown-it-mathjax3';

export default {
  components: {
    MarkdownItVue,
  },
  props: {
    content: { type: String, required: true },
  },
  data() {
    return {
      options: {
        markdownIt: {
          linkify: true,
          html: true, // Enable HTML tags in source
          typographer: true,
        },
        linkAttributes: {
          attrs: {
            target: '_self', //'_blank',
            rel: 'noopener',
          },
        },
        // katex: {
        //   throwOnError: true,
        //   errorColor: '#cc0000',
        // },
        // icons: 'font-awesome',
        // githubToc: {
        //   tocFirstLevel: 2,
        //   tocLastLevel: 3,
        //   tocClassName: 'toc',
        //   anchorLinkSymbol: '',
        //   anchorLinkSpace: false,
        //   anchorClassName: 'anchor',
        //   anchorLinkSymbolClassName: 'octicon octicon-link',
        // },
        // mermaid: {
        //   theme: 'default',
        // },
        // image: {
        //   hAlign: 'left',
        //   viewer: true,
        // },
      },
    };
  },
  methods: {},
  mounted() {
    // this.$refs.myMarkdownItVue.use(texMath, {
    //   engine: require('katex'),
    //   delimiters: 'dollars',
    //   katexOptions: { macros: { '\\RR': '\\mathbb{R}' } },
    // });
    // this.$refs.myMarkdownItVue.use(mdkatex, {});
    this.$refs.myMarkdownItVue.use(mathjax3, {});
  },
};
</script>

<style scoped>
/* .container {
  display: inline-flex;
  width: 100%;
}
.md-text {
  width: 47%;
}
.md-body {
  width: 50%;
  margin-left: 20px;
} */
</style>
