<template>
  <div>
    <MarkdownViewer class="pa-4" :content="readme" />
  </div>
</template>

<script>
import MarkdownViewer from '@/components/misc/MarkdownViewer';
import { mapGetters } from 'vuex';

export default {
  name: 'DocumentationSolver',
  components: {
    MarkdownViewer,
  },
  props: [],
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters('misc', ['readme']),
  },
  mounted() {},
};
</script>

<style scoped></style>
